
import { defineComponent } from 'vue';
import CrmList from '@/components/CrmList/CrmList.vue';
import useListData from './components/useRecycleListData';
import { loadPage } from '@/utils/publicMethods';
import router from '@/router';

export default defineComponent({
  components: { CrmList },
  props: {},
  setup() {
    const { filterOptions, columnList, tableRef } = useListData(3);
    const back = () => {
      router.push('/clue/clueList');
    };
    const refreshTable = () => {
      tableRef.value.refreshTable(false);
    };
    const rowClick = (row) => {
      loadPage('viewClue', {
        id: row.id,
        type: 3, // 1线索 2线索池 3回收站
      });
    };

    return {
      filterOptions,
      columnList,
      tableRef,
      refreshTable,
      back,
      rowClick,
    };
  },
});
